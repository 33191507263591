import React from 'react'
import { useState, useEffect, useRef } from 'react'
import '../Css/Lobby.css';
import CashGameTable from '../Components/CashGameTable';
import TournamentGameTable from '../Components/TournamentGameTable';
import SitNGoGameTable from '../Components/SitNGoGameTable';
import InfoTable from '../Components/InfoTable';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import socket from '../socket';
import Modal from 'react-modal';
import Container from 'react-bootstrap/Container';
// import Modal from 'react-bootstrap/Modal';
import Cashier from '../Components/Cashier';
import MyAccount from '../Components/MyAccount';
import VIPLevel from '../Components/VIPLevel';
const BackendURL = "http://ec2-13-231-89-219.ap-northeast-1.compute.amazonaws.com:8080/";


const lang = require('../Config/lang.js');

const LobbyScreen = () => {
  localStorage.removeItem("tableData");
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState({
    table: 'cashGame'
  })
  const [roomDetails, setNewRoomDetails] = useState([]);
  const [isRoomDetails, setIsRoomDetails] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [time, setTime] = useState('00:00');
  const [userData, setUserData] = useState({});
  const [gameData, setGameData] = useState({});
  const [addBanner, setAddBanner] = useState({});
  const [sidebarWidth, setSidebarWidth] = useState({});
  const [sidebarMobileWidth, setSidebarMobileWidth] = useState({});
  const [isCashierOpen, setIsCashierOpen] = useState(false);
  const [isMyProfileOpen, setIsMyProfileOpen] = useState(false);
  const [isVipLevelOpen, setIsVipLevelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [interval, setIntervalId] = useState('')
  const [userLocation, setUserLocation] = useState('');
  const [isAllowed, setIsAllowed] = useState(true);
  const [isAdvertisement, setIsAdvertisement] = useState(true);
  const [hourRemainingPopup, setHourRemainingPopup] = useState(false);
  const [thirtyMinRemainingPopup, setThirtyMinRemainingPopup] = useState(true);
  const [twentyMinRemainingPopup, setTwentyMinRemainingPopup] = useState(true);
  const [tenMinRemainingPopup, setTenMinRemainingPopup] = useState(true);
  const [activeTab, setActiveTab] = useState('allTra');
  const [hourRemainingPopupMsg, setHourRemainingPopupMsg] = useState('');
  const [hourSubscription, setHourSubscription] = useState(false);
  const [isBroadCastMessage, setIsBroadCastMessage] = useState(false);
  const [broadCastMessage, setBroadCastMessage] = useState({});
  const [showPopup, setShowPopup] = useState(localStorage.getItem('popupShown') !== 'false');
  const [isTableInfo, setTableInfo] = useState({
    tableInfo: false,
    myInfo: true
  })

  const handlePopupBtnClk = () => {
    // Set a flag in localStorage indicating that the popup should not be shown for the rest of the day
    localStorage.setItem('popupShown', 'false');
    //-- 하루 그만보기 수정
    localStorage.setItem('popupDate', new Date().toString());
    //
    setShowPopup(false);
    console.log(showPopup);
  };
  

  // Function to check if the current date is the same as the stored date in localStorage
  const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getFullYear() === date2.getFullYear();
  };

  // Function to reset the flag in localStorage at midnight
  const resetPopupFlag = () => {
    const currentDate = new Date();
    const storedDate = new Date(localStorage.getItem('popupDate'));
    if (!isSameDay(currentDate, storedDate)) {
      localStorage.removeItem('popupShown');
      localStorage.setItem('popupDate', currentDate.toString());
    }
  };

  // Call resetPopupFlag when the component mounts to reset the flag at midnight
  useEffect(() => {
    resetPopupFlag();
  }, []);

  const elementRef = useRef(null);

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);
  }, [window.innerWidth]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const socketId = socket.id;
    socket.emit('broadcast-message', { socketId })
    socket.on('broadcast-message', (data) => {
      if (data.playerId == user.playerId) {
        setIsBroadCastMessage(true);
        setBroadCastMessage(data)
        setTimeout(() => {
          setIsBroadCastMessage(false)
          setBroadCastMessage({})
        }, 10000)
      }
    });
  }, [socket]);

  useEffect(() => {
    setInterval(() => setTime(moment().format('hh:mm A')), 1000);
    const user = JSON.parse(localStorage.getItem('user'));
    setIsLoader(false);
    if (!user || user == null) {
      navigate('/')
    } else {
      const data = {
        playerId: user.playerId,
        productName: 'PokerHills'
      }

      socket.emit('GameSettings', data, (res) => {
        if (res.status == 'success') {
          if (res.result && res.result.hour_subscription) {
            if (res.result.hour_subscription === 'active') {
              setHourSubscription(true);
            } else {
              setHourSubscription(false);
            }
          }
        }
      });

      socket.emit('gameData', data, (res) => {
        if (res.status == 'success') {
          setGameData(res.result);
          
          //--추가
          getPlayerTableList();
        }
      });
      socket.emit('GetAddBanner', data, (res) => {
        if (res.status == 'success') {
          setAddBanner(res.result);
        }
      });

      socket.emit('Playerprofile', data, (res) => {
        localStorage.setItem('user', JSON.stringify(res.result));
        console.log(res.result);
        setUserData(res.result);
      });

      const intervalId = setInterval(() => {
        // socket.emit('gamePlayedLastTime', data, (res) => {
        // });
        socket.emit('Playerprofile', data, (res) => {
          localStorage.setItem('user', JSON.stringify(res.result));
          setUserData(res.result);
          if (hourSubscription == true) {
            if (res.result.hour_balance == "00:30") {
              if (thirtyMinRemainingPopup == true) {
                setHourRemainingPopup(true);
                setHourRemainingPopupMsg('You are running low balance of hour. Please recharge your playing hour.');
              }
            }
            if (res.result.hour_balance == "00:20") {
              if (twentyMinRemainingPopup) {
                setHourRemainingPopup(true);
                setHourRemainingPopupMsg('You are running low balance of hour. Please recharge your playing hour.');
              }
            }
            if (res.result.hour_balance == "00:10") {
              if (tenMinRemainingPopup) {
                setHourRemainingPopup(true);
                setHourRemainingPopupMsg('You are running low balance of hour. Please recharge your playing hour.');
              }
            }
            if (res.result.hour_balance == "00:00") {
              setHourRemainingPopup(true);
              setHourRemainingPopupMsg('You are running low balance of hour. Please buy hour to continue play.');
            }
          }
        });
        
        getPlayerTableList();
      }, 5000);

      const fetchUserLocation = async () => {
        try {
          const response = await fetch('https://ipinfo.io/?token=09898bb4ccfadf');
          const data = await response.json();
          setUserLocation(data.region);
        } catch (error) {
          console.error('Error fetching user location:', error);
        }
      };
      fetchUserLocation();

      setIntervalId(intervalId);

      return () => clearInterval(intervalId)
    }
  }, [thirtyMinRemainingPopup, twentyMinRemainingPopup, tenMinRemainingPopup]);


  const handleFullScreen = () => {
    const element = elementRef.current;

    if (element) {
      if (document.fullscreenElement || // Standard syntax
        document.webkitFullscreenElement || // Chrome, Safari and Opera syntax
        document.mozFullScreenElement || // Firefox syntax
        document.msFullscreenElement) { // IE/Edge syntax

        // Element is currently in fullscreen, so exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }

      } else {

        // Element is not in fullscreen, so enter fullscreen
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
          element.msRequestFullscreen();
        }
      }
    }
  };

  const closeReminderPopup = async () => {
    setHourRemainingPopup(false);
    if (thirtyMinRemainingPopup == true) {
      setThirtyMinRemainingPopup(false);
    }
    if (twentyMinRemainingPopup == true) {
      setTwentyMinRemainingPopup(false);
    }
    if (tenMinRemainingPopup == true) {
      setTenMinRemainingPopup(false);
    }
  }

  useEffect(() => {
    // Check if the user is in Texas
    if (userLocation !== 'TX') {
      setIsAllowed(true);
    } else {
      setIsAllowed(true);
    }
  }, [userLocation]);

  const setRoomDetails = (e) => {
    setNewRoomDetails(e)
    setIsRoomDetails(true)
  }

  const hideShowDiv = (e) => {
    if (isActive.table != e.target.id) {
      setIsActive({
        table: e.target.id,
      })
      setNewRoomDetails({})
      setIsRoomDetails(false)
    }
  }
  const tableInfoFunc = (e) => {
    setTableInfo({
      tableInfo: true,
    })
  }
  const hideRoomInfo = () => {
    setNewRoomDetails({})
    setIsRoomDetails(false)
  }
  const refreshData = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const data = {
      playerId: user.playerId,
      productName: 'PokerHills'
    }

    socket.emit('Playerprofile', data, (res) => {
      localStorage.setItem('user', JSON.stringify(res.result));
      setUserData(res.result);
    });
  }

  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png'
  }

  const closeSideBar = () => {
    setSidebarWidth({ width: '0%' });
    setSidebarMobileWidth({ width: '0%' });
  }

  const openCashier = () => {
    setActiveTab('allTra')
    setIsCashierOpen(true);
    closeSideBar();
  }

  const openCashierBuyHour = () => {
    setActiveTab('buyHour')
    setIsCashierOpen(true);
    closeSideBar();
  }

  const closeCashier = () => {
    setIsCashierOpen(false)
  }

  const openMyProfile = () => {
    setIsMyProfileOpen(true);
    closeSideBar();
  }

  const closeMyProfile = () => {
    setIsMyProfileOpen(false);
    closeSideBar();
  }

  const openMyVipLevel = () => {
    setIsVipLevelOpen(true);
    closeSideBar();
  }

  const closeMyVipLevel = () => {
    setIsVipLevelOpen(false);
    closeSideBar();
  }

  const logout = (e) => {
    localStorage.removeItem("user");
    //navigate('/');
    window.close();
  }
  
  //--추가 시작
   
    const [playerData, setPlayerData] = useState(JSON.parse(localStorage.getItem('user')));
    const [playerMultiTable, setPlayerMultiTable] = useState([])
    const getPlayerTableList = () => {
          //const socketUrl = io(BackendURL);
          // socketUrl.emit('playerTableList', { id: playerData.playerId, productName: 'PokerHills' }, (res) => {
          // });
          socket.emit('GetRunningGameList', { playerId: playerData.playerId, productName: 'PokerHills' }, (res) => {
              console.log("res.result")
              console.log(res.result)
             setPlayerMultiTable(res.result)
          });
      }
  	const [switchLoader, setSwitchLoader] = useState(false);
  	const [currentTableId, setCurrentTableId] = useState('');
  	const changeTableData = (tId) => {
          setSwitchLoader(true);
  
          setTimeout(() => {
              setSwitchLoader(false);
          }, 1000);
  
         
          setCurrentTableId('');
          navigate(`/game/${tId}`);
      }


  //--추가 끝

  let advertisementStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#000000',
      width: '50%',
      maxHeight: '80%',
      border: '1px solid #ffd050',
      borderRadius: '8px',
    },
  };
  let customStylesLogout = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#000000',
      width: '50%',
      height: '60%',
      border: '1px solid #ffd050'
    },
  };


  let broadCastMessagePopup = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#000000',
      width: '40%',
      height: '40%',
      border: '1px solid #ffd050'
    },
  };

  if (isMobile) {
    advertisementStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000',
        width: '60%',
        maxHeight: '80%',
        border: '1px solid #ffd050'
      },
    };
    customStylesLogout = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000',
        width: '60%',
        height: '60%',
        border: '1px solid #ffd050'
      },
    };

    broadCastMessagePopup = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000',
        width: '60%',
        height: '60%',
        border: '1px solid #ffd050'
      },
    }
  }

  return (
    <div ref={elementRef}>

      { showPopup && (
      <div>
      {isAdvertisement ? (
        <div>
          <Modal
            isOpen={true}
            style={advertisementStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <img src={'../Images/close.png'} onClick={() => setIsAdvertisement(false)} className='float-right cursor-pointer mb-2'></img>
            {addBanner.flashBanner && addBanner.flashBanner.image_name
              ?
              <img src={BackendURL + 'image/' + addBanner.flashBanner.image_name} width={'100%'} height={'100%'}></img>
              //  <img src={'../Images/TopAdd.png'} width={'100%'}></img>
              :
              <img src={'../Images/FlashBanner.jpg'} width={"100%"} height={"100%"}></img>
            }
            <div className="closeBtn-wrap">
              <button className="hideToday" onClick={handlePopupBtnClk}>오늘 하루 그만보기</button>
            </div>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
      </div>
      )}

      {isBroadCastMessage ? (
        <div>
          <Modal
            isOpen={true}
            style={broadCastMessagePopup}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
              <div className='modalBody'>
                <center>
                  <h2 className='color-1'>{broadCastMessage.heading}</h2>
                  <h4 className='color-1 mt-4'>{broadCastMessage.message}</h4>
                  <div className='avtarBtnSection'>
                    <button className='selectBuyInBtn' onClick={() => setIsBroadCastMessage(false)}>Close</button>
                  </div>
                </center>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div>
        </div>
      )}

      {isAllowed ? (
        <div>
        </div>
      ) : (
        <div>
          <Modal
            isOpen={true}
            style={customStylesLogout}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
              <div className='modalBody'>
                <center>
                  <h2 className='color-1'>{lang.ko.warning}</h2>
                  <h4 className='color-1 mt-4'>Sorry, this game is only available to Texas residents.</h4>
                  <div className='avtarBtnSection'>
                    <button className='selectBuyInBtn' onClick={() => logout()}>{lang.ko.logout}</button>
                  </div>
                </center>
              </div>
            </div>
          </Modal>
        </div>
      )}
      {hourRemainingPopup ? (
        <div>
          <Modal
            isOpen={true}
            style={customStylesLogout}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
              <div className='modalBody'>
                <center>
                  <h2 className='color-1'>{lang.ko.warning}</h2>
                  <h4 className='color-1 mt-4'>{hourRemainingPopupMsg}</h4>
                  <div className='avtarBtnSection'>
                    <button className='selectBuyInBtn' onClick={() => openCashierBuyHour()}>Buy Hour</button>
                    <button className='selectBuyInBtn' onClick={() => closeReminderPopup()}>Close</button>
                  </div>
                </center>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
      {/* Mobile Screen */}
      {isMobile ?
        <div className='mobileView'>
          {isLoader ? <div className='loader'>
            <img src={'../Images/Loader.gif'}></img>
          </div>
            : ''
          }
          <div className='lobby-page'>
            <div className='p-relative'>
              <div className='brandLogo'>
                <img src={'../Images/Logo2.png'} ></img>
              </div>
              <div className='mbuserProfile d-flex gap-2'>
                <div className='userInfo'>
                  <div className='userDetails'>
                    <div className='userDetails-lvl'>
                      <span className='userLevel'>({userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'})</span>
                      <span className='userName'>{userData ? userData.username : ''}</span>
                    </div>
                    <p className='userBalance'>$ {userData ? userData.chips?.toFixed(2) : ''}</p>
                  </div>
                  <a href="javascript:void(0)" onClick={() => openMyVipLevel()} className={'playerProfileWithFrameLobby vipLevelLobby' + userData.vipLevel} >
                    <img src={playerProfilePhoto(userData ? userData.avatar : '')} className='plyrProfilePicMain'></img>
                  </a>
                </div>
              </div>
              {/* Multi Table */}
              {playerMultiTable?.map((table, key) => {
                return (
                    <div className="mtable-wrap" tabindex="-1" onClick={() => changeTableData(table.roomId)}>
                        <div className="mtable-name">
                            {/* table.roomName.length > 10 ? table.roomName.slice(0, 8) + '...' : table.roomName */}
                            {table.roomName}
                        </div>
                    </div>
                )
              })}
              <div className='header-btns'>
                <div className='leftside-btns'>
                  <div className='h-btn cashier-btn' onClick={(e) => {
                  openCashier(e)
                }}>
                    CASHIER
                  </div>
                </div>

                <div className='rightside-btns'>
                  <div className='h-btn logout-btn' onClick={() => logout()}>
                  나가기
                  </div>
                  <div className='h-btn memberinfo-btn' onClick={() => openMyProfile()}>
                    회원정보
                  </div>
                </div>
              </div>
            </div>

            {isVipLevelOpen ?
              <VIPLevel refreshData={refreshData} closeMyProfile={closeMyVipLevel}></VIPLevel> :
              ''}

            {isMyProfileOpen ?
              <MyAccount refreshData={refreshData} closeMyProfile={closeMyProfile}></MyAccount> :
              ''}

            <div className='d-flex p-relative z-index-0 mtab-wrapper'>
              <div className='mtab-btn'>
                <button className={isActive.table === 'cashGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="cashGame"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }} style={{ width: "100%" }}>{lang.ko.cashgame}</button>
              </div>
              <div className='mtab-btn'>
                <button className={isActive.table === 'tournamentGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="tournamentGame"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }} style={{ width: "100%" }}>{lang.ko.tournaments}</button>
              </div>
              <div className='mtab-btn'>
                <button className={isActive.table === 'sitngoGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="sitngoGame"
                  onClick={(e) => {
                    hideShowDiv(e)
                  }} style={{ width: "100%" }}>{lang.ko.sit_go}</button>
              </div>
              
              {/* <button className={isActive.table === 'cashierTable' ? `btn p-hd-fill-btn mt-2  active` : 'btn p-hd-fill-btn mt-2 '} id="cashierTable"
                onClick={(e) => {
                  openCashier(e)
                }}>{lang.ko.cashier}</button> */}
            </div>
            <div className='h-100'>
              <div className={isActive.table === 'cashGame' ? `cashGame` : 'cashGame d-none'}>
                {isActive.table === 'cashGame' ? <CashGameTable openCashier={openCashierBuyHour} mobileView={isMobile} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></CashGameTable> : ''}
              </div>
              <div className={isActive.table === 'tournamentGame' ? `tournamentGame` : 'tournamentGame d-none'}>
                {isActive.table === 'tournamentGame' ? <TournamentGameTable mobileView={isMobile} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></TournamentGameTable> : ''}
              </div>
              <div className={isActive.table === 'sitngoGame' ? `sitngoGame` : 'sitngoGame d-none'}>
                {isActive.table === 'sitngoGame' ? <SitNGoGameTable mobileView={isMobile} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></SitNGoGameTable> : ''}
              </div>
              {isCashierOpen ?
                <Cashier hourSubscription={hourSubscription} closeCashier={closeCashier} activeTab={activeTab}></Cashier> :
                ''}
              {/* <div className='mbtextSection mt-2'>
                <h4>Monday, Participate for the tournament just at $ 12 USD.</h4>
                <p>Till now, 1400 players have  been registered. </p>
                <h4>Win the Biggest Jackpot !</h4>
                <p>Total, $ 60,000 USD . Play more hands, Increase the chance to be a winner.</p>
              </div> */}
            </div>
          </div>

          <div className='lobbyFooter'>
            <div className='footerLeft'>
              {/* <img className='m-0' src={'../Images/fullscreen.png'} onClick={() => handleFullScreen()}></img> */}
              <div className='info-time-wrapper'>
                <span className='footerText info-text time time-info'>{time}</span>
              </div>
            </div>
            <div className='footerRight'>
              <div className='info-item'>
                <span className='footerText txt-label players-info'>PLAYER</span>
                <span className='txt-data'>{gameData.players ? gameData.players : 0}</span>
              </div>

              <div className='info-item'>
                <span className='footerText txt-label '>TABLE</span>
                <span className='txt-data'>{gameData.table ? gameData.table : 0}</span>
              </div>
              
              <div className='info-item'>
                <span className='footerText txt-label '>TOURNAMENT</span>
                <span className='txt-data'>{gameData.tournament ? gameData.tournament : 0}</span>
              </div>
            </div>
          </div>
        </div>
        :
        // Desktop Screen
        <div>
          <div id="mySidenav" className="sidenav" style={sidebarWidth}>
            <a href="javascript:void(0)" className="sideBarClosebtn" onClick={() => closeSideBar()}>&times;</a>
            <center>
              <div className='sidebarUserImg'>
                <img src={playerProfilePhoto(userData ? userData.avatar : '')} ></img>
                <div className='userInfo'>
                  <p className='userName'>{userData ? userData.username : ''}</p>
                  <p className='userBalance'>$ {userData ? userData.chips?.toFixed(2) : 0.00}</p>
                </div>
                {/* <div className='timeInfo'>
                  <p className='userTime'>Remaining Time : {userData ? userData.hour_balance : '00.00'}</p>
                </div> */}
              </div>
            </center>
          </div>
          
          {isLoader ? <div className='loader'>
            <img src={'../Images/Loader.gif'}></img>
          </div>
            : ''
          }
          <div className='lobby-page'>
            <div className="header">
              <div className="header_block left">
                <div className="info_block">
                  <div className="info-item">
                    <div className="info-time-wrapper">
                      <div className="info-text time">{time}</div>
                    </div>
                  </div>
                  <div className="info-item">
                    <div className="info-text txt-label">PLAYER</div>
                    <div className="info-text txt-data">{gameData.players ? gameData.players : 0}</div>
                  </div>
                  <div className="info-item">
                    <div className="info-text txt-label">TABLE</div>
                    <div className="info-text txt-data">{gameData.table ? gameData.table : 0}</div>
                  </div>
                  <div className="info-item">
                    <div className="info-text txt-label">TOURNAMENT</div>
                    <div className="info-text txt-data">{gameData.tournament ? gameData.tournament : 0}</div>
                  </div>
                </div>
              </div>
              <div className="header_block right">
                <div className="cashier-wrapper">
                  <div className="cashier-btn" onClick={(e) => { openCashier(e) }}>
                    <div className="cashier-text">Cashier</div>
                  </div>
                </div>
                <div className="buttons_block">
                  <div className="fullscreen-btn">
                    <a href="javascript:void(0)" onClick={() => handleFullScreen()}>
                      <img src={(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) ?
                        '../Images/fullscreen-exit.png' : '../Images/fullscreen.png'} alt="fullscreen" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex header-bottom'>
              <div className='p-col-9 min-h-100'>
                <div className='d-flex min-h-100'>
                  <div className='p-col-3 min-h-100'>
                    <div className='pokerLogo  min-h-100'>
                      <img src={'../Images/Logo2.png'} />
                    </div>
                  </div>
                  
                  <div className="TableHeaderContainer__table_router_holder">
                    <div className="table-router-container">
                        <div className="scroll-panel" style={{ position: "relative", height: "100%", width: "100%", overflow: "hidden", touchAction: "none" }}>
                            <div className="scroll-wrapper">
                                {playerMultiTable?.map((table, key) => {
                                        return (
                                            <div className="panel TableIndicator TableIndicator_v_transparent TableIndicator_c_gray TableIndicatorContainer TableIndicatorContainer_v_default TableIndicatorContainer_c_dark table-indicator inactive" tabindex="-1" onClick={() => changeTableData(table.roomId)}>
                                                <div className="TableIndicator__content">
                                                    {table.roomName.length > 10 ? table.roomName.slice(0, 8) + '...' : table.roomName}
                                                </div>
                                            </div>
                                        )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                  
                </div>
              </div>
              <div className="jackpot_block">
                <div className="jackpot-wrapper">
                  <div className="jackpot-name">
                    <div className="jackpot-text">
                      <div className="name">Monte Carlo</div>
                      <div className="title">Jackpot</div>
                    </div>
                  </div>
                  <div className="jackpot-value">
                    <div className="jackpot-value-text">
                      <div className="wrapper">
                        <div className="value_item">2 500 000.00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='p-col-2-5 ms-0 max-height-80 p-relative  min-h-100'>
                <div className='userProfile d-flex gap-3  min-h-100'>
                  <a href="javascript:void(0)" onClick={() => openMyVipLevel()} className={'playerProfileWithFrameLobby vipLevelLobby' + userData.vipLevel} >
                    <img src={playerProfilePhoto(userData ? userData.avatar : '')} className='plyrProfilePicMain'></img>
                  </a>
                 
                  <div className='userInfo'>
                    <p className='userName'>{userData ? userData.username : ''}</p>
                    <p className="userVipLevel">
                      <span className='userBalance'>$ {userData ? userData.chips?.toFixed(2) : ''} </span>
                      <b>({userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'})</b>
                    </p>
                  </div>
                  <a href="javascript:void(0)" className="sideBarMenubtn" onClick={() => openMyProfile()}>
                    <img src={'../Images/Setting.png'}></img>
                  </a>
                </div>
              </div> */}
              
            </div>
            {isVipLevelOpen ?
              <VIPLevel refreshData={refreshData} closeMyProfile={closeMyVipLevel}></VIPLevel> :
              ''}

            {isMyProfileOpen ?
              <MyAccount refreshData={refreshData} closeMyProfile={closeMyProfile}></MyAccount> :
              ''}
            <div className='d-flex h-80'>
              <div className='p-col-9 min-h-100 p-relative'>
                <div className='buttonHeader'>
                  <div className='d-flex gap-2'>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'cashGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="cashGame"
                        onClick={(e) => {
                          hideShowDiv(e)
                        }}>{lang.ko.cashgame}</button>
                    </div>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'tournamentGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="tournamentGame"
                        onClick={(e) => {
                          hideShowDiv(e)
                        }}>{lang.ko.tournaments}</button>
                    </div>
                    <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'sitngoGame' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="sitngoGame"
                        onClick={(e) => {
                          hideShowDiv(e)
                        }}>{lang.ko.sit_go}</button>
                    </div>
                    {/* <div className='p-col-2 ms-0 p-relative'>
                      <button className={isActive.table === 'cashierTable' ? `btn p-hd-fill-btn active` : 'btn p-hd-fill-btn'} id="cashierTable"
                        onClick={(e) => {
                          openCashier(e)
                        }}>{lang.ko.cashier}</button>
                    </div> */}
                  </div>
                </div>
                {isCashierOpen ?
                  <Cashier hourSubscription={hourSubscription} closeCashier={closeCashier} activeTab={activeTab}></Cashier> :
                  ''}
                <div className='d-flex h-100'>
                  <div className='p-col-12 lobbyListing mt-2'>
                    <div className={isActive.table === 'cashGame' ? `cashGame active` : 'cashGame'}>
                      {isActive.table === 'cashGame' ? <CashGameTable openCashier={openCashierBuyHour} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></CashGameTable> : ''}
                    </div>
                    <div className={isActive.table === 'tournamentGame' ? `tournamentGame active` : 'tournamentGame'}>
                      {isActive.table === 'tournamentGame' ? <TournamentGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></TournamentGameTable> : ''}
                    </div>
                    <div className={isActive.table === 'sitngoGame' ? `sitngoGame active` : 'sitngoGame'}>
                      {isActive.table === 'sitngoGame' ? <SitNGoGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></SitNGoGameTable> : ''}
                    </div>
                    {/*
                    <div className={isActive.table === 'cashGame' ? `cashGame` : 'cashGame d-none'}>
                      {isActive.table === 'cashGame' ? <CashGameTable openCashier={openCashierBuyHour} setRoomDetails={setRoomDetails} isLoader={setIsLoader}></CashGameTable> : ''}
                    </div>
                    <div className={isActive.table === 'tournamentGame' ? `tournamentGame` : 'tournamentGame d-none'}>
                      {isActive.table === 'tournamentGame' ? <TournamentGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></TournamentGameTable> : ''}
                    </div>
                    <div className={isActive.table === 'sitngoGame' ? `sitngoGame` : 'sitngoGame d-none'}>
                      {isActive.table === 'sitngoGame' ? <SitNGoGameTable setRoomDetails={setRoomDetails} isLoader={setIsLoader}></SitNGoGameTable> : ''}
                    </div>
                    */}
                  </div>
                </div>
              </div>
              <div className='p-col-2-5 ms-0 p-relative'>
                <div className='lobbySideBox'>
                  <div className='buttonHeader'>
                    <div className='d-flex gap-2'>
                      <div className='p-col-12 ms-0 p-relative'>
                        <div className="userinfo_block">
                          <div className="user-info">
                            <div className="user-nicklevel">
                              <div className="user-level">
                                ({userData ? (userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze') : ''})
                                {/*({userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'})*/}
                              </div>
                              <div className="nickname-wrapper" tabindex="-1">
                                <div className="nickname-text" tabindex="-1">{userData && userData.username ? userData.username.split('_')[0] : ''}</div>
                              </div>
                            </div>
                            <div className="current-balance">
                              <div className="balance-wrapper">
                                <div className="balance-bg" >
                                  <div className="balance-text">$ {userData ? userData.chips?.toFixed(2) : ''}</div>
                                </div>
                              </div>
                          </div>
                          <div className="userinfo-btns">
                            <div className="userinfo-btn">
                              <div className="btn-out" onClick={() => logout()}>나가기</div>
                            </div>
                            <div className="userinfo-btn">
                              <a className="btn-infodetails" href="javascript:void(0)" onClick={() => openMyProfile()}>
                                회원정보
                              </a>
                            </div>
                          </div>
                          </div>
                          <div className="avatar_block" tabindex="-1">
                            <a href="javascript:void(0)" onClick={() => openMyVipLevel()} className={'playerProfileWithFrameLobby vipLevelLobby1'} >
                              <img src={playerProfilePhoto(userData ? userData.avatar : '')} className='avatar-img plyrProfilePicMain'></img>
                            </a>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='table-my-info'>
                    <InfoTable roomDetails={roomDetails} hideRoomInfo={hideRoomInfo}></InfoTable>
                    {/* <div className='bannerImageSec'>
                      <div className='staticImg'></div>
                    </div> */}
                  </div>
                  <div className='d-flex gap-2'>
                    <div className='p-col-12 ms-0 p-relative '>
                      {addBanner.secondary && addBanner.secondary.image_name
                        ?
                        <img src={BackendURL + 'image/' + addBanner.secondary.image_name} width={'100%'} height={'215px'}></img>
                        //  <img src={'../Images/TopAdd.png'} width={'100%'}></img>
                        : <img src={'../Images/AddBannerSec.jpg'} width={'100%'} height={'215px'}></img>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {/* 
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Body >
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal> */}

    </div>
  );
}

export default LobbyScreen

